<template>
  <div class="popup_wrap bl_payment_pop" style="width:1000px; height: 550px;">
    <button class="layer_close" style="z-index: 800;" @click="close()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX070G040.001') }}</h1> <!-- B/L 발행요청 -->
      <div class="content_box">
        <BLPaymentTarget
          ref="BLPaymentTarget"
          :bl-list="freightBillInfo.blList"
          :iss-ststus="freightBillInfo.issStstus"
          @setReqBizCatCd="setReqBizCatCd"
        >
        </BLPaymentTarget>
        <template v-if="isShow.method">
          <div class="mb10">
            <h2 class="content_title">{{ $t('msg.ONEX070G040.070') }}</h2>
            <table class="tbl_row">
              <tbody>
                <tr>
                  <td class="border_left_none">
                    <input type="radio" id="paytype-D" value="D" checked="checked">
                    <label for="paytype-D" class="mr20"><span></span>{{ $t('msg.SETT010T010.085') }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <table class="tbl_row mt10">
                <colgroup>
                  <col width="130"><col><col width="130"><col><col width="130"><col><col width="70">
                </colgroup>
                <tbody>
                  <tr>
                    <th colspan="6"><span class="color_black">銀行入金情報</span></th> <!-- 은행 입금 정보 -->
                    <th style="text-align: center">
                      <span @click="addDepositInfo()" style="cursor: pointer; font-weight: bold; font-size: 20px; color: #075bb9;">+</span>
                    </th>
                  </tr>
                  <template v-for="(di, i) in method.depositInfoArr">
                    <tr :key="'deposit-info-1-' + i">
                      <th>口座情報</th>
                      <td>
                        <select v-model="di.payCur" @change="changePayCur(i)" :id="'fbt-payCur-' + di.rowIdx">
                          <option value="">お選び下さい。</option>
                          <option v-for="(unit, j) in bankInfoArr" :key="'monetaryUnit-' + i + '-' + j">{{ unit.paycur }}</option>
                        </select>
                      </td>
                      <td colspan="2">
                        <select v-model="di.rcvBnkNm" @change="changeBank(i)" :id="'fbt-rcvBnkNm-' + di.rowIdx">
                          <option value="">お選び下さい。</option>
                          <option v-for="(bank, j) in di.bankArr" :key="'bank-'+ i + '-' + j">{{ bank.bank }}</option>
                        </select>
                      </td>
                      <td colspan="2">
                        <select v-model="di.payAcctNo" :id="'fbt-payAcctNo-' + di.rowIdx">
                          <option value="">お選び下さい。</option>   <!-- 선택해 주세요. -->
                          <option v-for="(accNo, j) in di.accNoArr" :key="'accNo-' + i + '-' + j">{{ accNo }}</option>
                        </select>
                      </td>
                      <td rowspan="2" class="text-center">
                        <span :style="i == 0 ? '' : 'cursor: pointer;'" @click="deleteDepositInfo(i)">
                          <template v-if="i > 0">
                            <span style="cursor: pointer; font-weight: bold; font-size: 20px; color: #075bb9;">-</span>
                          </template>
                        </span>
                      </td>
                    </tr>
                    <tr :key="'deposit-info-2-' + i">
                      <th class="acc-tr-border-bottom">振込人口座名</th> <!-- 송금인 계좌명 -->
                      <td class="acc-tr-border-bottom"><input type="text" v-model="di.rcvUsrNm" :id="'fbt-rcvUsrNm-' + di.rowIdx"></td>
                      <th class="acc-tr-border-bottom">{{ $t('msg.SETT010T010.074') }}</th>
                      <td class="acc-tr-border-bottom"><input type="text" @keyup="setComma(i)" v-model="di.payAmtComma" :id="'fbt-payAmt-' + di.rowIdx"></td>
                      <th class="acc-tr-border-bottom">{{ $t('msg.SETT010T010.082') }}</th>
                      <td class="acc-tr-border-bottom" @click="setCurrIdx(i)">
                        <div class="input_calendar mid">
                          <e-date-picker @input="chnageRcvDt" :value="di.rcvDt" :is-error="di.inputError" :id="'depositDt-' + i" :disabled="true" />
                        </div>
                        <span :id="'fbt-rcvDt-' + di.rowIdx"></span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <template v-if="isShow.methodIn">
          <div class="mb10">
            <div>
              <table class="tbl_row mt10">
                <colgroup>
                  <col width="130"><col><col width="130"><col><col width="130"><col><col width="70">
                </colgroup>
                <tbody>
                  <tr>
                    <th colspan="6"><span class="color_black">Deposit Information</span></th> <!-- 은행 입금 정보 -->
                  </tr>
                  <template v-for="(di, i) in method.depositInfoArr">
                    <tr :key="'deposit-info-1-' + i">
                      <th>Bank Name</th>
                      <td colspan="2">
                        <input type="text" :key="'bank-'+ i" v-model="di.rcvBnkNm" :id="'fbt-rcvBnkNm-' + di.rowIdx">
                      </td>
                      <th>UTR Number</th>
                      <td colspan="2">
                        <input type="text" v-model="di.payAcctNo" :id="'fbt-payAcctNo-' + di.rowIdx">
                      </td>
                    </tr>
                    <tr :key="'deposit-info-2-' + i">
                      <th class="acc-tr-border-bottom">Amount</th>
                      <td class="acc-tr-border-bottom" colspan="2">
                        <input type="text" @keyup="setComma(i)" v-model="di.payAmtComma" :id="'fbt-payAmt-' + di.rowIdx">
                      </td>
                      <th class="acc-tr-border-bottom">Customer Name</th>
                      <td class="acc-tr-border-bottom" colspan="2">
                        <input type="text" v-model="di.rcvUsrNm" :id="'fbt-rcvUsrNm-' + di.rowIdx">
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <h2 class="content_title">
          {{ $t('msg.SETT010T010.107') }} <!-- 결제 정보 -->
        </h2>
        <div>
          <!-- paddingRight: '0px' -->
          <table class="tbl_col">
            <colgroup>
              <col style="width:34%">
              <col style="width:33%">
              <col style="width:33%">
            </colgroup>
            <thead>
              <tr>
                <th class="bb">B/L No.</th>
                <th class="bb">Currency</th>
                <th class="bb">{{ $t('msg.CSDBL400_M2.0042') }} </th> <!-- 결제 정보 -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in billInfo" :key="'fbp-row1-' + i">
                <td>{{ row.blNo }}</td>
                <td>{{ row.locCurCd }}</td>
                <td class="text_right">{{ numberFormat(row.billLocAmt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="padding-right: 0px;">
          <table class="tbl_col">
            <colgroup>
              <col style="width:34%">
              <col style="width:33%">
              <col style="width:33%">
            </colgroup>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>B/L {{ billInfo?.length ?? 0 }}{{ $t('msg.UTIL010G010.010') }}</th>
                <th class="text_right">{{ numberFormat(totalAmt) }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click="paymentChk()">{{ $t('msg.ONIM099P010.002') }}</a> <!-- 발행 -->
        <a class="button gray lg" href="javascript:void(0)" @click="close()">{{ $t('msg.ONIM099P010.003') }}</a> <!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import BLPaymentTarget from '@/pages/trans/popup/BLPaymentTarget'
import FreightBillTax from '@/components/common/FreightBillTax'
import EDatePicker from '@/components/common/EDatePickerCustom'
import CalcIssue from '@/api/rest/settle/calcIssue'
import { rootComputed } from '@/store/helpers'
export default {
  name: 'BLPaymentEtcPop',
  components: {
    EDatePicker,
    BLPaymentTarget,
    FreightBillTax
  },
  props: {
    freightBillInfo: {
      type: Object,
      default: function () {
        return {
          blList: [],
          dpType: 'BLP'
        }
      }
    }
  },
  data: function () {
    return {
      reqBizCatCd: '',
      method: {
        depositInfoArr: [{
          payCur: '',
          rcvBnkNm: '',
          bankArr: [],
          payAcctNo: '',
          accNoArr: [],
          rcvUsrNm: '',
          payAmt: '',
          payAmtComma: '',
          rcvDt: '',
          rowIdx: 0,
          inputError: false
        }]
      },
      currIdx: null,
      bankInfoArr: [
        { paycur: 'JPY', bankList: [{ bank: 'みずほ銀行', accNoList: ['2879126', '0140462'] }] },
        { paycur: 'USD', bankList: [{ bank: 'みずほ銀行', accNoList: ['9178609'] }] }
      ],
      errArr: {
        info: [],
        method: []
      },
      clearArr: {
        info: [],
        method: []
      },
      isShow: { //세금계산서 화면 제어
        info: true, //결제정보
        method: true, //결제수단 선택
        dmdRadio: true,
        methodIn: true
      },
      billInfo: [],
      totalAmt: 0
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    errArr: {
      deep: true,
      handler () {
        for (let err of this.errArr.method) {
          if (err.id.indexOf('fbt-rcvDt') !== -1) {
            this.method.depositInfoArr[err.id.split('-')[2]].inputError = true
          } else {
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
          }
        }
      }
    },
    clearArr: {
      deep: true,
      handler () {
        for (let id of this.clearArr.method) {
          if (id.indexOf('fbt-rcvDt') !== -1) {
            this.method.depositInfoArr[id.split('-')[2]].inputError = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
          }
        }
      }
    }
  },
  created () {
  },
  mounted: function () {
    this.getIsShow()
    this.findBlFreight()
  },
  methods: {
    changePayCur (idx) {
      let payCur = this.method.depositInfoArr[idx].payCur
      this.method.depositInfoArr[idx].rcvBnkNm = ''
      this.method.depositInfoArr[idx].bankArr = []
      this.method.depositInfoArr[idx].payAcctNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          this.method.depositInfoArr[idx].bankArr = p.bankList
          break
        }
      }
    },
    changeBank (idx) {
      let rcvBnkNm = this.method.depositInfoArr[idx].rcvBnkNm
      let payCur = this.method.depositInfoArr[idx].payCur
      this.method.depositInfoArr[idx].payAcctNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          for (let b of p.bankList) {
            if (rcvBnkNm === b.bank) {
              this.method.depositInfoArr[idx].accNoArr = b.accNoList
              break
            }
          }
        }
      }
    },
    parseReqData (info) {
      let res = {}
      let vrAccNoList = []
      if (this.memberDetail.userCtrCd === 'JP') {
        let blNoArr = info.blList.map((bl) => {
          return bl.blNo
        }).join(',')

        let jpFileInfo = null
        const target = this.$refs.BLPaymentTarget

        if (this.$ekmtcCommon.isNotEmpty(target.formData.jpFileInfo.fileId)) {
          jpFileInfo = target.formData.jpFileInfo
        }

        res = {
          reqBizCatCd: '',
          asBlNo: blNoArr,
          blNo: blNoArr,
          payAcctList: this.method.depositInfoArr,
          type: 'D',
          dpType: 'BLP',
          jpFileInfo: jpFileInfo
        }
      } else if (this.memberDetail.userCtrCd === 'IN') {
        res.payAcctList = this.method.depositInfoArr
      }
      return res
    },
    paymentChk () {
      if (this.freightBillInfo.toOrderYn === 'Y') {
        //Consignee 가 TO ORDER 또는 TO THE ORDER 인 경우, WAYBILL 발행이 불가합니다. \r\n자세한 사항은 담당자에게 문의 바랍니다. (02-311-6114)
        const msg = this.$t('art.CSBL220.011')
        if (this.reqBizCatCd) {
          if (this.reqBizCatCd === '02') {
            const TH = this

            if (this.memberDetail.userCtrCd === 'JP') {
              if (this.freightBillInfo.bankYn === 'Y') {
                this.msgAlert(this.$t('art.CSBL220.024')) //This BL is to order,  surrender requests are prohibited.
                return
              }
            }

            this.$ekmtcCommon.confirmCallBack(msg, () => { TH.payment() })
            return
          } else if (this.reqBizCatCd === '03') {
            this.msgAlert(msg)
            return
          }
        } else {
          this.msgAlert(this.$t('art.CSBL220.019')) //발행 구분을 다시 선택해주세요.
          return
        }
      }
      this.payment()
    },
    //결제
    async payment () {
      //세금계산서 컴포넌트별 데이터 emit
      if (this.memberDetail.userCtrCd !== 'JP' || (this.memberDetail.userCtrCd === 'JP' && this.validationMethod('payBtn'))) {
        let req = this.parseReqData(this.freightBillInfo)

       await this.$refs.BLPaymentTarget.fnPayment2(req).then((result) => {
          console.log('payment req @@@@ ', req)
          if (result.data === 2) {
            this.msgAlert(this.$t('art.CSBL220.016'))
          } else if (result.data === 3) {
            this.msgAlert(this.$t('art.CSBL220.010'))
          } else if (result.data === 1) {
            this.msgAlert(this.$t('art.CSBL220.017'))
            this.$store.commit('settle/updateIsSettleSearchData', true)
          } else {
            this.msgAlert(this.$t('art.CSBL220.018'))
          }
          this.close()
        })
      }
    },
    validationMethod (type) {
      let errArr = []
      let clearArr = []
      if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
        for (let row of this.method.depositInfoArr) {
          if (this.isEmpty(row.payCur)) {
            errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: this.$t('msg.CSBL220.017') })
          } else {
            clearArr.push('fbt-payCur-' + row.rowIdx)
          }

          if (this.isEmpty(row.rcvBnkNm)) {
            errArr.push({ id: 'fbt-rcvBnkNm-' + row.rowIdx, msg: this.$t('msg.CSBL220.018') })
          } else {
            clearArr.push('fbt-rcvBnkNm-' + row.rowIdx)
          }

          if (this.isEmpty(row.payAcctNo)) {
            errArr.push({ id: 'fbt-payAcctNo-' + row.rowIdx, msg: this.$t('msg.CSBL220.019') })
          } else {
            clearArr.push('fbt-payAcctNo-' + row.rowIdx)
          }
          if (this.isEmpty(row.rcvUsrNm)) {
            errArr.push({ id: 'fbt-rcvUsrNm-' + row.rowIdx, msg: this.$t('msg.CSBL220.020') })
          } else {
            clearArr.push('fbt-rcvUsrNm-' + row.rowIdx)
          }

          if (this.isEmpty(row.payAmt)) {
            errArr.push({ id: 'fbt-payAmt-' + row.rowIdx, msg: this.$t('msg.CSBL220.021') })
          } else {
            clearArr.push('fbt-payAmt-' + row.rowIdx)
          }

          if (this.isEmpty(row.rcvDt)) {
            errArr.push({ id: 'fbt-rcvDt-' + row.rowIdx, msg: this.$t('msg.CSBL220.022') })
          } else {
            clearArr.push('fbt-rcvDt-' + row.rowIdx)
          }
        }
        this.clearArr.method = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.method = errArr
          }
          return false
        }
      } else {
        return true
      }
    },
    getIsShow () {
      //영수,청구 등에 구분에 따라 보여줄 컴포넌트 제어(SA002I - ET_DMD_CAT_CD)
      let isShow = {
        info: true,
        method: true,
        dmdRadio: true,
        methodIn: true
      }

      if (this.memberDetail.userCtrCd === 'JP') {
        isShow.method = true
      } else {
        isShow.method = false
      }

      if (this.memberDetail.userCtrCd === 'IN') {
        isShow.methodIn = true
      } else {
        isShow.methodIn = false
      }

      this.isShow = isShow
    },
    addDepositInfo () {
      if (this.method.depositInfoArr.length >= 3) {
        let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$t('msg.ONEX010P100.036'),
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$t('msg.CSBL220.023'),
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
      } else {
        this.rowIdx++
        this.method.depositInfoArr.push(
        {
          payCur: '',
          rcvBnkNm: '',
          bankArr: [],
          payAcctNo: '',
          accNoArr: [],
          rcvUsrNm: '',
          payAmt: '',
          payAmtComma: '',
          rcvDt: '',
          rowIdx: this.rowIdx,
          inputError: false
        }
      )
      }
    },
    deleteDepositInfo (idx) {
      if (idx > 0) {
        this.method.depositInfoArr.splice(idx, 1)
      }
    },
    chnageRcvDt (data) {
       setTimeout(this.method.depositInfoArr[this.currIdx].rcvDt = data, 1000)
    },
    setCurrIdx (idx) {
      this.currIdx = idx
    },
    setComma (idx) {
      this.method.depositInfoArr[idx].payAmt = this.method.depositInfoArr[idx].payAmtComma.replace(/,/gi, '')
      this.method.depositInfoArr[idx].payAmtComma = this.numberFormat(this.method.depositInfoArr[idx].payAmt)
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    setReqBizCatCd (reqBizCatCd) {
      this.reqBizCatCd = reqBizCatCd
    },
    close () {
      this.$emit('close')
      this.$refs.BLPaymentTarget.reset()
    },
    msgAlert (msg) {
      let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.ONEX010P100.036'), // 닫기
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async findBlFreight () {
      const arrBlNo = this.freightBillInfo?.blList.map(item => item.blNo) ?? []
      if (arrBlNo.length > 0) {
          const param = {
              arrBlNo,
              eiCatCd: 'O'
          }
          await CalcIssue.getBlFreightKrw(param).then(async response => {
            let totalAmt = 0
            this.billInfo = Object.keys(response.data).map((item) => {
              const info = response.data[item].at(0)
              totalAmt += Number(info.billLocAmt)
              return info
            })
            this.totalAmt = totalAmt
          })
      }
    }
  }
}
</script>
